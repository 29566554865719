import * as React from "react";
import { Container, Row, Col } from 'react-bootstrap';

import QrNavbar from "../components/QrNavbar";
import { QrSEO } from "../components/QrHead";

const Post = () => {
    return (
        <div id="qr-wrap">
            <QrSEO title="Qranio - Post" pageName="Post" pageClass="Post" />
            <QrNavbar />
            <section id="post">
                <Container>
                    <Row className="justify-content-center">
                        <Col xs={12} xl={10}>
                            <h1>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit
                            </h1>
                            <p className="lead">
                                This is a longer card with supporting text below as a natural
                                lead-in to additional content. This content is a little bit
                                longer.
                            </p>
                            <Row>
                                <Col xs={6}>
                                    <p className="m-0">
                                        <strong>Por: Qranio</strong><br />
                                        <small>13/09/2022 às 14:23</small>
                                    </p>
                                </Col>
                                <Col xs={6}>
                                    <div className="social-share">
                                        Mídias
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <hr />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} xl={10}>
                            <article>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Maecenas sed enim ut sem viverra aliquet eget sit.
                                    Ornare suspendisse sed nisi lacus sed viverra. Tincidunt vitae semper quis lectus. Aliquam nulla facilisi cras fermentum odio eu feugiat pretium. Risus nullam eget felis eget.
                                    Elementum nisi quis eleifend quam adipiscing vitae proin. Tristique magna sit amet purus gravida. Tempus quam pellentesque nec nam aliquam sem et. Ut venenatis tellus in metus vulputate eu.
                                    Ut ornare lectus sit amet est placerat. Quis risus sed vulputate odio ut enim blandit volutpat.
                                </p>
                                <p>
                                    Nisl condimentum id venenatis a condimentum vitae. Ut placerat orci nulla pellentesque dignissim enim. Nunc lobortis mattis aliquam faucibus purus in. Odio ut enim blandit volutpat maecenas.
                                    Ultrices dui sapien eget mi proin. Venenatis tellus in metus vulputate eu. Tellus at urna condimentum mattis pellentesque. Faucibus et molestie ac feugiat sed lectus vestibulum mattis.
                                    Eget gravida cum sociis natoque penatibus et. Diam quam nulla porttitor massa id neque aliquam vestibulum. Semper auctor neque vitae tempus. Bibendum enim facilisis gravida neque convallis a cras semper.
                                    At in tellus integer feugiat. Id ornare arcu odio ut sem nulla pharetra diam. Amet justo donec enim diam vulputate ut pharetra.
                                </p>
                                <p>
                                    Amet consectetur adipiscing elit duis. Pellentesque habitant morbi tristique senectus et netus et. Quam adipiscing vitae proin sagittis nisl rhoncus.
                                    Tempus iaculis urna id volutpat lacus laoreet non curabitur gravida. Erat pellentesque adipiscing commodo elit at imperdiet dui. Porttitor rhoncus dolor purus non enim.
                                    Ut morbi tincidunt augue interdum velit euismod in pellentesque. Ligula ullamcorper malesuada proin libero nunc consequat interdum. Rutrum quisque non tellus orci ac auctor augue mauris.
                                    At auctor urna nunc id cursus metus. Et netus et malesuada fames ac turpis egestas. Nulla facilisi morbi tempus iaculis. Tortor pretium viverra suspendisse potenti nullam ac tortor vitae.
                                    Pulvinar elementum integer enim neque volutpat ac. Tempor nec feugiat nisl pretium fusce id velit. Nisi lacus sed viverra tellus in hac habitasse.
                                </p>
                                <p>
                                    Consectetur a erat nam at lectus urna. Mauris commodo quis imperdiet massa tincidunt nunc pulvinar. Aliquam vestibulum morbi blandit cursus risus at ultrices mi.
                                    Aliquam ut porttitor leo a diam sollicitudin tempor id. Blandit cursus risus at ultrices. Gravida quis blandit turpis cursus in hac habitasse platea dictumst. At varius vel pharetra vel turpis nunc eget.
                                    Aenean et tortor at risus viverra adipiscing at in tellus. Orci eu lobortis elementum nibh. Viverra justo nec ultrices dui sapien eget mi proin. Ut enim blandit volutpat maecenas volutpat blandit aliquam etiam erat.
                                    Gravida neque convallis a cras semper auctor neque.
                                </p>
                                <p>
                                    Amet commodo nulla facilisi nullam vehicula. Mi ipsum faucibus vitae aliquet nec ullamcorper sit amet risus. Tellus cras adipiscing enim eu turpis egestas pretium aenean.
                                    Faucibus pulvinar elementum integer enim neque volutpat ac. Condimentum id venenatis a condimentum vitae sapien pellentesque habitant morbi. Elementum sagittis vitae et leo duis ut diam quam.
                                    Ac turpis egestas maecenas pharetra. Gravida quis blandit turpis cursus in hac. Volutpat commodo sed egestas egestas fringilla. Neque aliquam vestibulum morbi blandit cursus risus.
                                    Gravida arcu ac tortor dignissim. Purus in mollis nunc sed id semper risus in hendrerit. Sed nisi lacus sed viverra. Tempus iaculis urna id volutpat lacus laoreet non curabitur.
                                    Ac odio tempor orci dapibus ultrices in iaculis nunc sed. At varius vel pharetra vel turpis nunc eget lorem. Facilisis mauris sit amet massa vitae tortor condimentum lacinia. Mi proin sed libero enim sed faucibus.
                                </p>
                            </article>
                        </Col>
                        <Col xs={12}>
                            Últimos posts
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default Post
